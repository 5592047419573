// private config
const development = {
    endpoints: {
        backend: 'https://alight.dev.api.r2s.genesysinnoapps.net',
    }
}

const production = {
    endpoints: {
        backend: 'https://alight.api.r2s.genesysinnoapps.com',
    }
}

const qa = {
    endpoints: {
        backend: 'https://alight.dev.api.r2s.genesysinnoapps.net',
    }
}

const env = process.env.REACT_APP_CUSTOM_ENV.trim()
console.log('env:', env)
let config
switch (env) {
    case 'production': config = production; break;
    case 'development': config = development; break;
    case 'qa': config = qa; break;
    default: break;
}

export default config
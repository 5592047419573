import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';

const AlertMessage = (props) => {
  const [visible, setVisible] = useState(props.visible)
  const onDismiss = () => props.setVisible(false)

  useEffect(() => {
    setVisible(props.visible)
  }, [props])

  useEffect(() => {
    if (visible) setTimeout(() => { onDismiss() }, 10000)
  }, [visible])

  return (
    <Alert color="warning" isOpen={visible} toggle={onDismiss}>
      {props.message}
    </Alert>
  );
}

export default AlertMessage;
import React, { useState, useEffect } from 'react'
import ReactTable from "react-table-6";
import { Input, Collapse, List, ListInlineItem, Tooltip } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import Header from '../header/Header';
import Footer from '../footer/Footer';
import ConversationsSearchCriteria from '../conversationsSearchCriteria/ConversationsSearchCriteria'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faCog , faDownload,faFileZipper , faCloudArrowDown} from '@fortawesome/free-solid-svg-icons'
import backend from '../../services/backend'
import AlertMessage from '../alertMessage/AlertMessage'
import WaitingModal from '../Modals/waitingModal/WaitingModal'
import AlertModal from '../Modals/alertModal/AlertModal'
import moment from 'moment'
import "react-table-6/react-table.css";
import './Home.css'
import constants from '../../constants'

const pageSize = sessionStorage.getItem(constants.SESSION_KEY_CONVERSATIONS_PAGESIZE) ? parseInt(sessionStorage.getItem(constants.SESSION_KEY_CONVERSATIONS_PAGESIZE)) : 15

const Home = (props) => {
    const [error, setError] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [conversations, setConversations] = useState([])
    const [user, setUser] = useState(undefined)
    const [searchAlertVisible, setSearchAlertVisible] = useState(false)
    const [searchAlertMessage, setSearchAlertMessage] = useState()
    const [columns, setColumns] = useState([])
    const [waitingHeader, setWaitingHeader] = useState()
    const history = useHistory()

    // Tooltips
    const [searchConversationsTooltipOpen, setSearchConversationsTooltipOpen] = useState(false)
    const [hideColumnsTooltipOpen, setHideColumnsTooltipOpen] = useState(false)

    // Collapsable
    const [isColumnsSettingsOpen, setIsColumnsSettingsOpen] = useState(false)
    const [isConversationsSearchCriteriaOpen, setIsConversationsSearchCriteriaOpen] = useState(true)

    //columns to display
    const [columnsToDisplay, setColumnsToDisplay] = useState()

    const fetchData = async (searchCriteria) => {
        try {
            setWaitingHeader('Loading conversations')
            setIsWaitingModalOpen(true)
            const response = await backend.getConversations(sessionStorage.getItem('r2s-token'), searchCriteria)
            console.log('fetchData.response:', JSON.stringify(response))
            if (response.records_returned === 0) {
                setSearchAlertVisible(true)
                setSearchAlertMessage(`No records returned. Please verify your search criteria.`)
            }
            if (response.records_returned < response.total_records) {
                setSearchAlertVisible(true)
                setSearchAlertMessage(`Showing ${response.records_returned} of ${response.total_records} records. To find specific set of results, please refine your search criteria.`)
            }
            setConversations(response.conversations)
            sessionStorage.setItem('r2s-conversations', JSON.stringify(response.conversations))
        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }
    }

    useEffect(() => { // initial load
        const fetchSettings = async () => {
            try {
                setWaitingHeader('Loading conversation settings')
                setIsWaitingModalOpen(true)

                // Get users me
                const response = await backend.getUsersMe(sessionStorage.getItem('r2s-token'))
                setUser(response.user)

                // Get user conversation settings
                const conversationsColumns = response.user.conversationColumns.filter(x => x.visible)
                const columnsToDisplay = {}
                for (const column of conversationsColumns) {
                    columnsToDisplay[column.value] = column.visible
                }
                setColumnsToDisplay(columnsToDisplay)

                // Build re set columns
                const columns = conversationsColumns.map(x => {
                    const col = {
                        Header: x.label,
                        accessor: x.value,
                        headerClassName: 'headerColumn',
                        // Filter: ({ filter, onChange }) => { return customFilterInput(filter, onChange, x.value) },
                        show: x.visible,
                        Cell: row => {
                            return (
                                <div className="overflow div-center">
                                    {row.original[x.value]}
                                </div>
                            )
                        }
                    }

                    if (x.type === 'date') {
                        col.Cell = row => (<div className="overflow div-center">{moment(row.original[x.value]).format("MMM Do YYYY, h:mm:ss a")}</div>)
                        col.filterable = false
                    }

                    return col
                })
                setColumns(columns)
            } catch (error) {
                setError(`An error occured while fetching conversation settings:${JSON.stringify(error.message)}`)
                console.log(error)
            } finally {
                setIsWaitingModalOpen(false)
            }
        }
        console.log('Home.useEffect.init')
        fetchSettings()
        const conversations = JSON.parse(sessionStorage.getItem('r2s-conversations'))
        if (conversations) setConversations(conversations)
    }, [])

    useEffect(() => { // update columns show property
        console.log('columnsToDisplay.useEffect.trigger:', columnsToDisplay)
        if (!columnsToDisplay || columns.length === 0) {
            console.log('columnsToDisplay.useEffect.exiting use effect')
            return
        }
        console.log('columnsToDisplay.useEffect.processing use effect')
        for (const key in columnsToDisplay) {
            if (Object.hasOwnProperty.call(columnsToDisplay, key)) {
                columns.find(x => x.accessor === key).show = columnsToDisplay[key]
            }
        }
        setColumns([...columns])

    }, [columnsToDisplay])


    const search = async (searchCriteria) => {
        console.log('getRecordings.searchCriteria:', searchCriteria)
        fetchData(searchCriteria)
        toggleConversationsSearchCriteria(false)
    }

    const toggleColumnsSettings = () => {
        setIsConversationsSearchCriteriaOpen(false)
        setIsColumnsSettingsOpen(!isColumnsSettingsOpen)
    }
    const toggleConversationsSearchCriteria = () => {
        setIsColumnsSettingsOpen(false)
        setIsConversationsSearchCriteriaOpen(!isConversationsSearchCriteriaOpen)
    }

    return (
        <div id="component-wrap">
            <Header role={props.role} />
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header={waitingHeader}
            />
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />
            <Tooltip placement="bottom" isOpen={searchConversationsTooltipOpen}
                target="searchConversations"
                toggle={() => { setSearchConversationsTooltipOpen(!searchConversationsTooltipOpen) }}>
                Search conversations
            </Tooltip>
            <Tooltip placement="bottom" isOpen={hideColumnsTooltipOpen}
                target="hideColumns"
                toggle={() => { setHideColumnsTooltipOpen(!hideColumnsTooltipOpen) }}>
                Show/Hide columns
            </Tooltip>
            <div id="component-toolbar">
                <button id="searchConversations" onClick={toggleConversationsSearchCriteria}><FontAwesomeIcon className="fa-2x" icon={faFilter} /></button>
                <button id="hideColumns" onClick={toggleColumnsSettings}><FontAwesomeIcon className="fa-2x" icon={faCog} /></button>
            </div>
            <AlertMessage
                message={searchAlertMessage}
                visible={searchAlertVisible}
                setVisible={x => { setSearchAlertVisible(x) }}
            />
            <Collapse isOpen={isColumnsSettingsOpen}>
                <List type="inline" className="ml-2">
                    {columns && columns.map((column) => {
                        return (
                            <ListInlineItem>
                                <Input addon type="checkbox"
                                    onChange={(e) => {
                                        e.persist()
                                        setColumnsToDisplay((prevState) => ({ ...prevState, [column.accessor]: e.target.checked }))
                                    }}
                                    checked={columnsToDisplay[column.accessor]}
                                /> {column.Header}{'\u00A0'}
                            </ListInlineItem>
                        )
                    })}
                </List>
            </Collapse>
            <Collapse isOpen={isConversationsSearchCriteriaOpen}>
                {user &&
                    <ConversationsSearchCriteria
                        search={search}
                        cancel={() => { toggleConversationsSearchCriteria(false) }}
                        channels={user.channels ? user.channels.filter((x => x.value !== '*')) : []}
                        queues={user.queues ? user.queues.filter((x => x.value !== '*')) : []}
                        role={user.role ? user.role.value : undefined}
                        clients={user.clients ? user.clients.filter((x => x.value !== '*')) : []}
                    />
                }
            </Collapse>
            <ReactTable
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={pageSize}
                minRows={0}
                filterable
                // manual
                // pages={pages}
                className="-highlight -striped table-container"
                data={conversations}
                columns={columns}
                getTdProps={(state, rowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            try {
                                if (!rowInfo) return
                                history.push(`/conversations/${rowInfo.original.conversation_id}/details`)
                            } catch (error) {
                                setError('An error occured while selecting a row, please check console logs for more details')
                                console.log(error)
                            }
                        },
                        style: {
                            cursor: 'pointer'
                        }
                    }
                }}
                onPageSizeChange={(pageSize) => {
                    sessionStorage.setItem('r2s-conversations-pageSize', pageSize)
                }}
                getTrProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                getTrGroupProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                defaultFilterMethod={(filter, row) => {
                    const id = filter.pivotId || filter.id;
                    return (row[id] !== undefined ?
                        String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                        : true
                    );
                }}
            />
            <Footer />
        </div>
    )
}

export default Home
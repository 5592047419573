import React, {useEffect, useState} from 'react'
import { useHistory} from 'react-router-dom'
import {Container} from 'reactstrap'
import Header from '../../../header/Header';
import Footer from '../../../footer/Footer'
import UserDetails from '../UserDetails/UserDetails'
import backendAPI from '../../../../services/backend'
import AlertModal from '../../../Modals/alertModal/AlertModal'
import ConfirmationModal from '../../../Modals/confirmationModal/ConfirmationModal'
import WaitingModal from '../../../Modals/waitingModal/WaitingModal'
import constants from '../../../../constants'
import './UserCreate.css'

let usercreatresult = ''
const UserCreate = (props) => {
    const history = useHistory()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [message, setMessage] = useState()
    const [error, setError] = useState()
    useEffect(() => { 
    }, [])


    const onSubmit = async (user) => {
        try {
        console.log(`User Create Submitted with ${JSON.stringify(user)}`);
        setIsWaitingModalOpen(true);
        usercreatresult = await backendAPI.createUser(sessionStorage.getItem(constants.R2S_TOKEN),user);
        console.log(`User Create API response ${JSON.stringify(usercreatresult)}`);
        setIsWaitingModalOpen(false);
        setMessage(`The user ${user.emailAddressEntered} has been created successfully`);
        const timeout = setTimeout(() => {
            history.push('/management/users/');
          }, 2000);
        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`);
            console.log(error);
        } finally {
            setIsWaitingModalOpen(false)
        }
    }

    const onCancel = async () => {
        history.push('/management/users/')

    }

    return (
        <div id="component-wrap">
            <Header role={props.role}/>
            <div id="main">
                    <WaitingModal
                    isOpen={isWaitingModalOpen}
                    header='Creating the new user'
                     />
                    <ConfirmationModal
                    isOpen={!!message}
                    header='Success!'
                    toggle={() => { setMessage(undefined) }}
                    body={message}
                    />
                    <AlertModal
                    isOpen={!!error}
                    header='Error'
                    toggle={() => { setError(undefined) }}
                    body={error}
                    />
                    < br />
                     <label className = "title-name">Create User</label>
                <hr className = "hori-line"/>
                    <UserDetails
                        okButtonText='Create'
                        cancelButtonText='Cancel'
                        submit={onSubmit}
                        cancel={onCancel}
                    />
            </div>
     
            <Footer version="0.1" />
        </div>
    )
}

export default UserCreate
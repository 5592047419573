import React, { useState, useEffect } from 'react'
import {MultiSelect} from "react-multi-select-component"
import './AccessReportSearchCriteria.css'
import moment from 'moment';
// import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;
const {__version__} = require('../../__version__');

const AccessReportSearchCriteria = (props) => {
    const { clients, users, actions } = props
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [selectedClients, setSelectedClients] = useState([])
    const [selectedActions, setSelectedActions] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [startSearch, setStartSearch] = useState(false)
    const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date

    const search = async () => {
        console.log('searching...')
        setStartSearch(true)
        // if (!startDate || !endDate) return        
        const searchCriteria = new Map()
        // if (startDate) searchCriteria.set('startDate', startDate.toISOString())
        // if (endDate) searchCriteria.set('endDate', endDate.toISOString())

        if (!dateRange[0] || !dateRange[1]) return        
        if (dateRange[0]){
            let tempStartDate = moment(dateRange[0],'YYYY-MM-DD').set({"hour": 0, "minute": 0});           
            searchCriteria.set('startDate', tempStartDate.toISOString());
        } 

        if (dateRange[1]){
            let tempEndDate = moment(dateRange[1],'YYYY-MM-DD').set({"hour": 23, "minute": 59});
            searchCriteria.set('endDate',tempEndDate.toISOString() );
        } 

        if (selectedClients) {
            if (selectedClients.length === clients.length) // all
                searchCriteria.set('clients', '*')
            else
                searchCriteria.set('clients', selectedClients.map(x => x.value))
        }
        if (selectedActions) {
            if (selectedActions.length === actions.length) // all
                searchCriteria.set('actions', '*')
            else
                searchCriteria.set('actions', selectedActions.map(x => x.value))
        }
        if (selectedUsers) {
            if (selectedUsers.length === users.length) // all
                searchCriteria.set('users', '*')
            else
                searchCriteria.set('users', selectedUsers.map(x => x.value))
        }
        props.search(searchCriteria)
    }
    const cancel = async () => {
        console.log('canceling...')
        props.cancel()
    }

    const getDatePickerClassName = () => {
        return !startSearch || (startDate && endDate) ?
            'input-group d-inline-flex w-auto'
            : 'input-group d-inline-flex w-auto date-picker-error'
    }
    function onSelectDate(dateArray, dateString) 
    {        
        console.log(dateString)
        setdateRange(dateString);
    }
    return (
        <div className="search-wrap pl-2">
            <div className="row justify-content-start">
                <div className="col col-12 col-xl-3">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Users</span>
                        </div>
                        <MultiSelect
                            options={users}
                            value={selectedUsers}
                            onChange={e => { setSelectedUsers(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>
                {/* <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Clients</span>
                        </div>

                        <MultiSelect
                            options={clients}
                            value={selectedClients}
                            onChange={e => { setSelectedClients(e) }}
                            labelledBy="Select"
                        />

                    </div>
                </div> */}
            </div>
            <div className='row justify-content-start'>
                <div className="col col-12 col-xl-3">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Actions</span>
                        </div>
                        <MultiSelect
                            options={actions}
                            value={selectedActions}
                            onChange={e => { setSelectedActions(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>
                <div className='col col-12 col-xl-auto'>
                    <div className="input-group d-inline-flex align-items-center w-auto">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Interval</span>
                        </div>
                        <RangePicker placement="bottomLeft" style={{borderRadius:"5px",padding:"7px"}} onChange={onSelectDate} />
                    </div>
                </div>
            </div>
            <br />
           
            <div className='row justify-content-center d-flex flex-row-reverse bd-highlight'>

                <div className='col col-2 '>
                    <button type="button" className="btn btn-primary btn-space" onClick={search}>Search</button>
                    <button type="button" className="btn btn-secondary btn-space" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>

    )
}

export default AccessReportSearchCriteria
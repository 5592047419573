import React, { useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'

const Main = () => {
    const history = useHistory()

    useEffect(() => {
        const init = async () => {
            console.log('main.useEffect.init')
            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString)
            const token = urlParams.get('token')
            const error = urlParams.get('error')
            console.log('token:', token)
            if (token)
                history.push({ pathname: '/disclaimer', state: { token: token } })
            if (error) {
                console.log('error:', error)
                history.push('/unauthorized')
            }
        }
        init()
    }, [history])

    return (<Fragment></Fragment>)
}

export default Main
import React, { useState, useEffect } from 'react'
import {MultiSelect} from "react-multi-select-component"
import moment from 'moment'
import utils from '../../services/utils'
import constants, { SEARCH_PARAM_DEPARTMENT } from '../../constants'
import './ConversationsSearchCriteria.css'
//import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const ConversationsSearchCriteria = (props) => {
    const { channels, queues, role, clients } = props
    const [compactedQueues, setCompactedQueues] = useState([])
    const [topic, setTopic] = useState()
    const [subTopic, setSubTopic] = useState()
    const [customerNumber, setCustomerNumber] = useState()
    const [customerID, setCustomerID] = useState()
    const [customerName, setCustomerName] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [selectedChannels, setSelectedChannels] = useState([])
    const [selectedQueues, setSelectedQueues] = useState([])
    const [selectedClients, setSelectedClients] = useState([])
    const [startSearch, setStartSearch] = useState(false)
    const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date

    function onSelectDate(dateArray, dateString) {
        console.log(`onSelectDate value is ${JSON.stringify(dateString)}`);
        setdateRange(dateString);
    }

    // init from session storage
    useEffect(() => {
        try {
            const voiceChannel = channels.find(x => x.value.toLowerCase() === 'voice')
            if (voiceChannel) setSelectedChannels([voiceChannel])
            else setSelectedChannels(channels)

            // Fix for removing duplicate queue names for Search Criteria Filter - start            
            let resultMap = new Map();
            queues.forEach(x => {
                if(resultMap.get(x.label)){
                    resultMap.set(x.label, resultMap.get(x.label)+','+x.value);
                } else{
                    resultMap.set(x.label,x.value);
                }
            });            
            setCompactedQueues(Array.from(resultMap, ([key, value]) => ({ label:key, value })));            
            // Fix for removing duplicate queue names for Search Criteria Filter - finish

            const searchCriteriaTemp = JSON.parse(sessionStorage.getItem('r2s-conversations-search-criteria'))
            if(searchCriteriaTemp){
                try {
                    if(searchCriteriaTemp.startDateTime && searchCriteriaTemp.endDateTime){
                        let tempStartDate = new Date(searchCriteriaTemp.startDateTime);
                        console.log(`Setting Start date time as ${JSON.stringify(searchCriteriaTemp.startDateTime)}`);
                        console.log(`Setting Start date time as ${tempStartDate}`);
                        console.log(`Setting Start date time as ${typeof tempStartDate}`);
                        let tempEndDate = new Date(searchCriteriaTemp.endDateTime);
                        console.log(`Setting Start date time as ${JSON.stringify(searchCriteriaTemp.endDateTime)}`);
                        console.log(`Setting Start date time as ${tempEndDate}`);
                        console.log(`Setting Start date time as ${typeof tempEndDate}`);
                        setdateRange(tempStartDate,tempEndDate);
                        
                    }
                } catch (error) {
                     console.log(error);
                }
            }
            if (!searchCriteriaTemp) return;

            if (searchCriteriaTemp.startDateTime) setStartDate(moment(searchCriteriaTemp.startDateTime))
            if (searchCriteriaTemp.endDateTime) setEndDate(moment(searchCriteriaTemp.endDateTime))
            if (searchCriteriaTemp.customerNumber) setCustomerNumber(searchCriteriaTemp.customerNumber)
            if (searchCriteriaTemp.customerID) setCustomerID(searchCriteriaTemp.customerID)
            if (searchCriteriaTemp.customerName) setCustomerName(searchCriteriaTemp.customerName)
            if (searchCriteriaTemp.selectedQueues) setSelectedQueues(searchCriteriaTemp.selectedQueues)
            if (searchCriteriaTemp.topic) setTopic(searchCriteriaTemp.topic)
            if (searchCriteriaTemp.subTopic) setSubTopic(searchCriteriaTemp.subTopic)
            if (searchCriteriaTemp.selectedClients) setSelectedClients(searchCriteriaTemp.selectedClients)
            if (searchCriteriaTemp.selectedChannels) setSelectedChannels(searchCriteriaTemp.selectedChannels)
        } catch (error) {
            console.log('conversationsSearchCriteria.useeffect.error', error)
        }
    }, [])

    const search = async () => {
        setStartSearch(true)
        if (!dateRange[0] || !dateRange[1]) return
        console.log('Searching...')
        const searchCriteria = new Map()
        if (dateRange[0]){
            let tempStartDate = moment(dateRange[0],'YYYY-MM-DD').set({"hour": 0, "minute": 0});           
            searchCriteria.set(constants.SEARCH_PARAM_STARTDT, tempStartDate.toISOString());
        } 

        if (dateRange[1]){
            let tempEndDate = moment(dateRange[1],'YYYY-MM-DD').set({"hour": 23, "minute": 59});
            searchCriteria.set(constants.SEARCH_PARAM_ENDDT,tempEndDate.toISOString() );
        } 
    
        //if (startDate) searchCriteria.set('startDateTime', startDate.toISOString())
        //if (endDate) searchCriteria.set('endDateTime', endDate.toISOString())
        if (customerNumber) searchCriteria.set('customerNumber', customerNumber)
        if (customerID) searchCriteria.set('customerID', customerID)
        if (customerName) searchCriteria.set('customerName', customerName)
        if (selectedChannels && selectedChannels.length!=0) {
            //searchCriteria.set('selectedChannels', selectedChannels)
            if (selectedChannels.length === channels.length) // all
                searchCriteria.set('channels', '*')
            else
                searchCriteria.set('channels', decodeURIComponent(selectedChannels.map(x => x.value)))
        }
        if (selectedQueues && selectedQueues.length!=0) {
            //searchCriteria.set('selectedQueues', selectedQueues)
            if (selectedQueues.length === compactedQueues.length) // all
                searchCriteria.set('queues', '*')
            else
                searchCriteria.set('queues', decodeURIComponent(selectedQueues.map(x => x.value)))
        }
        if (selectedClients && selectedClients.length!=0) {
            //searchCriteria.set('selectedClients', selectedClients)
            if (selectedClients.length === clients.length) // all
                searchCriteria.set('clients', '*')
            else
                searchCriteria.set('clients', decodeURIComponent(selectedClients.map(x => x.value)))
        }
        if (topic) searchCriteria.set('topic', topic)
        if (subTopic) searchCriteria.set('subTopic', subTopic)
        props.search(utils.mapToObj(searchCriteria));
        sessionStorage.setItem('r2s-conversations-search-criteria', JSON.stringify(utils.mapToObj(searchCriteria)))
    }
    const cancel = async () => {
        console.log('canceling...')
        props.cancel()
    }

    const getDatePickerClassName = () => {
        return !startSearch || (startDate && endDate) ?
            'input-group d-inline-flex w-auto'
            : 'input-group d-inline-flex w-auto date-picker-error'
    }

    return (
        <div className="search-wrap pl-2 pb-2">
            <div className='row justify-content-start'>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Channels</span>
                        </div>
                        <MultiSelect
                            options={channels}
                            value={selectedChannels}
                            onChange={e => { console.log('setSelectedChannels:', e); setSelectedChannels(e) }}
                            labelledBy="Select"
                            disableSearch={true}
                        />
                    </div>
                </div>
                <div className='col col-12 col-xl-3' >
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Queues</span>
                        </div>
                        <MultiSelect className="multi-select queues-multi-select"
                            options={compactedQueues}
                            value={selectedQueues}
                            onChange={e => { setSelectedQueues(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>                
                <div className='col col-12 col-xl-auto'>
                    <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Interval *</span>
                            </div>
                            <RangePicker size="large" aria-required placement="bottomLeft" style={{ borderRadius: "5px" }} onChange={onSelectDate} />
                    </div>                   
                </div>
            </div>
            <div className='row justify-content-start'>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Customer Name</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={customerName}
                            onChange={e => setCustomerName(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Customer ID</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={customerID}
                            onChange={e => setCustomerID(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Customer Number</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={customerNumber}
                            onChange={e => setCustomerNumber(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className='row justify-content-start'>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Topic</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={topic}
                            onChange={e => setTopic(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Sub Topic</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={subTopic}
                            onChange={e => setSubTopic(e.target.value)} />
                    </div>
                </div>
                {role && (role === "admin" || role === "internal_user") && (
                    <div className='col col-12 col-xl-3'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Clients</span>
                            </div>

                            <MultiSelect  className="multi-select clients-multi-select"
                                options={clients}
                                value={selectedClients}
                                onChange={e => { setSelectedClients(e) }}
                                labelledBy="Select"
                            />

                        </div>
                    </div>
                )}
            </div>
            <br />            
            <div className='row justify-content-center d-flex flex-row-reverse bd-highlight'>
                <div className='col col-2 '>
                    <button type="button" className="btn btn-primary btn-space" onClick={search}>Search</button>
                    <button type="button" className="btn btn-secondary btn-space" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>

    )


}

export default ConversationsSearchCriteria
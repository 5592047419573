import React, { useState, useEffect, Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import backend from '../../services/backend'
import ReactTable from "react-table-6";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faFileVideo, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import RecordingPlaybackModal from '../Modals/recordingPlaybackModal/RecordingPlaybackModal'
import EmailTranscriptView from '../emailTranscriptView/EmailTranscriptView'
import ChatTranscriptView from '../chatTranscriptView/ChatTranscriptView'
import WaitingModal from '../Modals/waitingModal/WaitingModal'
import AlertModal from '../Modals/alertModal/AlertModal'
import constants from '../../constants'
import moment from 'moment'

import './ConversationDetails.css'
import "react-table-6/react-table.css";

const ConversationDetails = (props) => {
    const [error, setError] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [recordings, setRecordings] = useState([])
    const [conversation, setConversation] = useState()
    const [isRecordingPlaybackModalOpen, setIsRecordingPlaybackModalOpen] = useState(false)
    const [recordingPlayback, setRecordingPlayback] = useState()
    const [role, setRole] = useState()
    const history = useHistory()
    const { id } = useParams()

    useEffect(() => { // init load
        setRole(sessionStorage.getItem(constants.R2S_USER_ROLE));
        console.log('Conversation Details role is'+role);
        const fetchConversation = async () => {
            try {
                setIsWaitingModalOpen(true)
                let response = await backend.getConversation(sessionStorage.getItem(constants.R2S_TOKEN), id)
                console.log('getConversation.response:', response)
                setConversation(response)
                if (response.recordings) {
                    response = await backend.getConversationMetadata(sessionStorage.getItem(constants.R2S_TOKEN), id, response.recordings)
                    setRecordings(response.recordings)
                }
            } catch (error) {
                console.log('fetchConversation:,', error)
                setError(`An error occured while fetching the conversation:${JSON.stringify(error.message)}`)
            } finally {
                setIsWaitingModalOpen(false)
            }
        }
        fetchConversation()
    }, [id])

    const handleRecordingClick = (recording) => {
        console.log('Conversation details :::> handleRecordingClick:', recording)
        setRecordingPlayback(recording)
        toggleRecordingPlaybackModal()
    }

    const toggleRecordingPlaybackModal = () => setIsRecordingPlaybackModalOpen(!isRecordingPlaybackModalOpen)

    const conditionallyRenderConversation = () => {
        if (!conversation) return
        switch (conversation.channel.toLowerCase()) {
            case 'chat':
                return <Fragment>
                    {conversation.recordings &&
                        <ChatTranscriptView
                            id={conversation.conversation_id}
                            recordings={conversation.recordings}
                        />}
                </Fragment>

            case 'email':
                return <Fragment>
                    {conversation.recordings &&
                        <EmailTranscriptView
                            id={conversation.conversation_id}
                            recordings={conversation.recordings}
                        />}
                </Fragment>
            default: // voice
                return <Fragment>
                    <div className="px-2">
                    <Row>
                        <Col>
                            <span className="details-header">Conversation Recordings</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {(!conversation.recordings || conversation.recordings.length==0) && <span className="details-value">No recordings are available</span>}
                    
                            {conversation.recordings && conversation.recordings.length!=0 &&                     
                                    <ReactTable
                                        showPageSizeOptions={true}
                                        pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                                        defaultPageSize={10}
                                        minRows={0}
                                        className="-highlight -striped table-container"
                                        data={recordings}
                                        columns={[{
                                            Header: 'Recording Start Time',
                                            accessor: 'start_time',
                                            headerClassName: 'headerColumn',
                                            Cell: row => {
                                                return (
                                                    <div className="overflow div-center">
                                                        {moment(row.original.start_time).format("MMM Do YYYY, h:mm:ss a")}
                                                    </div>
                                                )
                                            }
                                        }, {
                                            Header: 'Recording End Time',
                                            accessor: 'end_time',
                                            headerClassName: 'headerColumn',
                                            Cell: row => {
                                                return (
                                                    <div className="overflow div-center">
                                                        {moment(row.original.end_time).format("MMM Do YYYY, h:mm:ss a")}
                                                    </div>
                                                )
                                            }
                                        }, {
                                            Header: 'Recording Duration',
                                            accessor: 'duration',
                                            headerClassName: 'headerColumn',
                                            Cell: row => {
                                                return (
                                                    <div className="overflow div-center">
                                                        {new Date(row.original.duration * 1000).toISOString().substr(11, 8)}
                                                    </div>
                                                )
                                            }
                                        }, {
                                            Header: 'Agent',
                                            accessor: 'username',
                                            headerClassName: 'headerColumn',
                                            Cell: row => {
                                                return (
                                                    <div className="overflow div-center">
                                                        {row.original.username}
                                                    </div>
                                                )
                                            }
                                        }, {
                                            Header: 'Screen',
                                            accessor: 'screen',
                                            headerClassName: 'headerColumn',
                                            Cell: row => {
                                                return (
                                                    <div className="overflow div-center">
                                                        {row.original.screen}
                                                    </div>
                                                )
                                            }
                                        }, {
                                            Header: 'Play',
                                            headerClassName: 'headerColumn',
                                            Cell: row => {
                                                switch (row.original.media_type.toLowerCase()) {
                                                    case 'screen': return (
                                                        <div className="div-center">
                                                            <button onClick={() => { handleRecordingClick(row.original) }}>
                                                                <FontAwesomeIcon icon={faFileVideo} />
                                                            </button>
                                                        </div>
                                                    )
                                                    case 'call': return (
                                                        <div className="div-center">
                                                            <button onClick={() => { handleRecordingClick(row.original) }}>
                                                                <FontAwesomeIcon icon={faPlay} />
                                                            </button>
                                                        </div>
                                                    )
                                                    default: return <Fragment></Fragment>
                                                }
                                            }
                                        }
                                        ]}
                                        getTdProps={(state, rowInfo, column, instance) => {
                                            return {
                                                style: {
                                                    cursor: 'pointer'
                                                }
                                            }
                                        }}
                                        getTrProps={() => {
                                            return {
                                                style: {
                                                    flex: '0 0 auto'
                                                }
                                            }
                                        }}
                                        getTrGroupProps={() => {
                                            return {
                                                style: {
                                                    flex: '0 0 auto'
                                                }
                                            }
                                        }}
                                        defaultFilterMethod={(filter, row) => {
                                            const id = filter.pivotId || filter.id;
                                            return (row[id] !== undefined ?
                                                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                                                : true
                                            );
                                        }}
                                    />}
                        </Col>
                    </Row>
                    </div>
                </Fragment>
        }
    }

    return (
        <div id="component-wrap">
            <Header role={props.role} />
            <div id="main">
                <WaitingModal
                    isOpen={isWaitingModalOpen}
                    header='Loading conversation details'
                />
                <AlertModal
                    isOpen={!!error}
                    header='Error'
                    toggle={() => { setError(undefined) }}
                    body={error}
                />

                <RecordingPlaybackModal
                    isOpen={isRecordingPlaybackModalOpen}
                    toggle={() => { toggleRecordingPlaybackModal() }}
                    recording={recordingPlayback}
                />

                <div className="px-2">
                    <Row>
                        <Col>

                            <button onClick={() => { history.push('/conversations') }}>
                                <FontAwesomeIcon className="fa-2x" icon={faChevronCircleLeft} />
                            </button>
                            <span className="conversations-details-header-text">Conversation Details - {conversation && conversation.conversation_id}</span>

                            <hr />
                        </Col>
                    </Row>
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Start Date</span><span className="details-value">{conversation && moment(conversation.conversation_start).format("MMM Do YYYY, h:mm:ss a")}</span>
                        </Col>
                        <Col>
                            <span className="details-header">End Date</span><span className="details-value">{conversation && moment(conversation.conversation_end).format("MMM Do YYYY, h:mm:ss a")}</span>
                        </Col>
                    </Row>
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Last Agent</span><span className="details-value">{conversation && conversation.agent_name}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Division</span><span className="details-value">{conversation && conversation.division_name}</span>
                        </Col>
                    </Row>
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Topic</span><span className="details-value">{conversation && conversation.topic}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Queue</span><span className="details-value">{conversation && conversation.queue_name}</span>
                        </Col>
                    </Row>
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Sub Topic</span><span className="details-value">{conversation && conversation.sub_topic}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Handle Duration</span><span className="details-value">{conversation && new Date(conversation.handle_time * 1000).toISOString().substr(11, 8)}</span>
                        </Col>
                    </Row>
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Conversation Direction</span><span className="details-value">{conversation && conversation.originating_direction}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Talk & Hold</span><span className="details-value">{conversation && new Date(conversation.talk_hold_time * 1000).toISOString().substr(11, 8)}</span>
                        </Col>
                    </Row>
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Language</span><span className="details-value">{conversation && conversation.language}</span>
                        </Col>
                        <Col>
                            <span className="details-header">ACW</span><span className="details-value">{conversation && new Date(conversation.acw_time * 1000).toISOString().substr(11, 8)}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                </div>
                {conditionallyRenderConversation()}
            </div>
            <Footer />
        </div>
    )
}

export default ConversationDetails
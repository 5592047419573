import React, { useState, useEffect } from 'react'
import ReactTable from "react-table-6";
import { Collapse, Tooltip } from 'reactstrap'
import Header from '../../header/Header'
import Footer from '../../footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faDownload } from '@fortawesome/free-solid-svg-icons'
import backend from '../../../services/backend'
import AlertMessage from '../../alertMessage/AlertMessage'
import CommonSearchTermsSearchCriteria from '../../commonSearchTermsSearchCriteria/CommonSearchTermsSearchCriteria';
import WaitingModal from '../../Modals/waitingModal/WaitingModal'
import AlertModal from '../../Modals/alertModal/AlertModal'
import moment from 'moment'
import utils from '../../../services/utils'
import './CommonSearchTerms.css'

const CommonSearchTerms = (props) => {
    const [clients, setClients] = useState(undefined)
    const [users, setUsers] = useState(undefined)
    const [searchAlertVisible, setSearchAlertVisible] = useState(false)
    const [searchAlertMessage, setSearchAlertMessage] = useState()
    const [terms, setTerms] = useState([])
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [error, setError] = useState()
    const [waitingHeader, setWaitingHeader] = useState()

    // Collapsable
    const [isSearchCriteriaOpen, setIsSearchCriteriaOpen] = useState(true)

    // Tooltips
    const [searchTooltipOpen, setSearchTooltipOpen] = useState(false)
    const [downloadTooltipOpen, setDownloadTooltipOpen] = useState(false)

    useEffect(() => { // initial load
        const fetchData = async () => {
            try {
                setWaitingHeader('Loading users')
                setIsWaitingModalOpen(true)
                const promises = []
                promises.push(backend.getUserListAll(sessionStorage.getItem('r2s-token')))
                promises.push(backend.getUsersMe(sessionStorage.getItem('r2s-token')))
                const responses = await Promise.all(promises)
                for (const response of responses) {
                    if (response.users) { // users list
                        const users = response.users.map(x => ({
                            label: `${x.firstName} ${x.lastName}`,
                            value: x.id
                        }))
                        setUsers(users)
                    }

                    if (response.user) { // clients for current user
                        setClients(response.user.clients)
                    }
                }
            } catch (error) {
                setError(`An error occured while fetching users:${JSON.stringify(error.message)}`)
                console.log(error)
            } finally {
                setIsWaitingModalOpen(false)
            }
        }
        fetchData()
    }, [])

    const fetchAudit = async (searchCriteria) => {
        try {
            setWaitingHeader('Loading audit')
            setIsWaitingModalOpen(true)
            const response = await backend.getAuditTermsReport(sessionStorage.getItem('r2s-token'), searchCriteria)
            // console.log('fetchData.response:', response)
            if (response.records_returned < response.total_records) {
                setSearchAlertVisible(true)
                setSearchAlertMessage(`Showing ${response.records_returned} of ${response.total_records} records. To find specific set of results, please refine your search criteria.`)
            }
            setTerms(response.terms)
            sessionStorage.setItem('r2s-terms', JSON.stringify(response.terms))
        } catch (error) {
            setError(`An error occured while fetching audit:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }
    }

    const search = async (searchCriteria) => {
        console.log('AccessReport.searchCriteria:', searchCriteria)
        fetchAudit(searchCriteria)
        toggleSearchCriteria(false)
    }

    const toggleSearchCriteria = () => setIsSearchCriteriaOpen(!isSearchCriteriaOpen)

    const DownloadReport = async () => {
        utils.JSONToCSVConvertor(terms, `R2S_CommonSearchTermsReport_${moment().format()}`, true)
    }

    return (
        <div id="component-wrap">
            <Header role={props.role} />
            <div id="component-toolbar">
                <button id="searchCommonTermsReport" onClick={toggleSearchCriteria}><FontAwesomeIcon className="fa-2x" icon={faFilter} /></button>
                <button id="downloadReport"
                    disabled={terms && terms.length > 0 ? false : true}
                    onClick={DownloadReport}><FontAwesomeIcon className="fa-2x" icon={faDownload} />
                </button>
            </div>
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header={waitingHeader}
            />
            <Tooltip placement="bottom" isOpen={searchTooltipOpen}
                target="searchCommonTermsReport"
                toggle={() => { setSearchTooltipOpen(!searchTooltipOpen) }}>
                Define common search terms report criteria
            </Tooltip>
            <Tooltip placement="bottom" isOpen={downloadTooltipOpen}
                target="downloadReport"
                toggle={() => { setDownloadTooltipOpen(!downloadTooltipOpen) }}>
                Download report to CSV
            </Tooltip>
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />
            <AlertMessage
                message={searchAlertMessage}
                visible={searchAlertVisible}
                setVisible={x => { setSearchAlertVisible(x) }}
            />
            <Collapse isOpen={isSearchCriteriaOpen}>
                {clients && users &&
                    <CommonSearchTermsSearchCriteria
                        search={search}
                        cancel={() => { toggleSearchCriteria(false) }}
                        clients={clients.filter((x => x.value !== '*'))}
                        users={users.filter((x => x.value !== '*'))}
                    />
                }
            </Collapse>
            <ReactTable
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={15}
                minRows={0}
                filterable
                className="-highlight -striped table-container"
                data={terms}
                columns={[{
                    Header: 'Customer Name',
                    accessor: 'customerName',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {row.value}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Customer ID',
                    accessor: 'customerID',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {row.value}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Customer Number',
                    accessor: 'customerNumber',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {row.value}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Topic',
                    accessor: 'topic',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {row.value}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Sub Topic',
                    accessor: 'subTopic',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {row.value}
                            </div>
                        )
                    }
                },
                {
                    Header: 'Count',
                    accessor: 'count',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {row.value}
                            </div>
                        )
                    }
                },
                {
                    Header: 'User Names',
                    accessor: 'userNames',
                    headerClassName: 'headerColumn',
                    Cell: row => {
                        return (
                            <div className="overflow div-center">
                                {row.value}
                            </div>
                        )
                    }
                }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                    return {
                        style: {
                            cursor: 'pointer'
                        }
                    }
                }}
                getTrProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                getTrGroupProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                defaultFilterMethod={(filter, row) => {
                    const id = filter.pivotId || filter.id;
                    return (row[id] !== undefined ?
                        String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                        : true
                    );
                }}
            />
            <Footer />
        </div>)
}

export default CommonSearchTerms